import React from 'react'
import { List,Grid,Breadcrumb } from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import  '../App.css';

 function Posts() {
    return (
        <>
        <Grid columns={3} >
         <Breadcrumb>
        <Breadcrumb.Section><Link to={'/'} >الصفحة الرئيسية</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>ألبوم الاسرة</Breadcrumb.Section>
      </Breadcrumb>
        <Grid.Row className="page-title">
       
        <h1>ألبوم الأسرة</h1>
        </Grid.Row>
        </Grid>
        <div className="grid">
  
    <a href="https://s3.eu-west-1.amazonaws.com/alsehaim.com/%D8%B4%D8%AC%D8%B1%D8%A9.pdf" className="card">
      <img src="https://s3.eu-west-1.amazonaws.com/alsehaim.com/%D8%B4%D8%AC%D8%B1%D8%A9+%D8%A7%D9%84%D8%B3%D8%AD%D9%8A%D9%85.png" width="200" height="200" alt=""/>
        <div className="card-text">
          <h1 className="card-title">شجرة العائلة</h1>
          <h2 className="card-subtitle"><b>شجرة العائلة </b></h2>
          <p className="card-desc">نسخة عالية الجودة من شجرة العائلة لعام ١٤٤٠</p>
        </div>
    </a>
    

  </div>
      </>
    )
}

export default Posts;