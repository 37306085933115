import React,{useState, useEffect} from 'react'
import { useQuery } from '@apollo/react-hooks';
import { Icon, Card, Image,Grid,Breadcrumb, Input } from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import {FETCH_USERS_QUERY} from '../util/graphql'

export const UsersList = () => {
  const {loading,data:{users:users}} = useQuery(FETCH_USERS_QUERY)
  const [searchInput, setSearchInput] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    if(! loading) {
          setFilteredResults(users)
    }

  }, [users])

  const searchItems = (searchValue) => {
    // setSearchInput(searchValue)
      var searchArr = searchValue.split(" بن ")
          var filteredData = users;
          for (let i = 0; i < searchArr.length; i++) {
            switch (i) {
              case 0:
                 filteredData = filteredData.filter(item => item.fname.startsWith(searchArr[i]) )
                 break;
              case 1:
                 filteredData = filteredData.filter(item => item.sname.startsWith(searchArr[i]) )
                 break;
              case 2:
                 filteredData = filteredData.filter(item => item.tname.startsWith(searchArr[i]) )
                 break;
              case 3:
                 filteredData = filteredData.filter(item => item.foname.startsWith(searchArr[i]) )

                break;
            
              default:
                break;
            }
            
          }
        setFilteredResults(filteredData)
}


    return (
      <>
      <Grid columns={12} >
      <Breadcrumb>
     <Breadcrumb.Section><Link to={'/'} >الصفحة الرئيسية</Link></Breadcrumb.Section>
     <Breadcrumb.Divider />
     <Breadcrumb.Section active>دليل الأسرة</Breadcrumb.Section>
   </Breadcrumb>
     <Grid.Row className="page-title">
    
     <h1>دليل الأسرة</h1>
     </Grid.Row>
     </Grid>
     <div className="chats">
     <Input icon='search'
                placeholder='بحث...'
                onChange={(e) => searchItems(e.target.value)}

            />
     {users && filteredResults.map((user, index)=>(

<div className="chat-user" key={index}>
<div className="chat-user-icon"><img src="https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png" alt=""/></div>
<div className="chat-user-details">
  <span className="chat-username">{user.fname} بن {user.sname} بن {user.tname} بن {user.foname}</span>
  <span className="last-msg-time hj">{user.city}          <a href={'https://wa.me/966'+user.phone}><i className='whatsapp icon'></i> </a> <a href={'tel:+966'+user.phone}><i className='call icon'></i> </a></span>
</div>
</div>  
     ))}
     </div>
     <br/>
</>
    )
}
