import React, { useContext, useState } from 'react';
import { Grid, Form, Button } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { AuthContext } from '../context/auth';
import { useForm } from '../util/hooks';
import  '../App.css';

function Login(props) {
  const context = useContext(AuthContext);
  const [errors, setErrors] = useState({});

  const { onChange, onSubmit, values } = useForm(loginUserCallback, {
    phone: '',
    password: ''
  });

  const [loginUser, { loading }] = useMutation(LOGIN_USER, {
    update(
      _,
      {
        data: { login: userData }
      }
    ) {
      context.login(userData);
      props.history.push('/');
    },
    onError(err) {
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: values
  });

  function loginUserCallback() {
    loginUser();
  }

  return (
    <div className="form-container">
      <Form onSubmit={onSubmit} noValidate className={loading ? 'loading' : ''}>
      <Grid.Row className="page-title">
      <h1>صفحة الدخول</h1>
      </Grid.Row>
      <label > رقم الجوال</label>
        <Form.Input
         
          placeholder="٠٥٩٩٩٩٩٩٩٠"
          name="phone"
          type="text"
          value={values.phone}
          error={errors.phone ? true : false}
          onChange={onChange}
        />
        <label > كلمة السر</label>
        <Form.Input
    
          placeholder="كلمة السر"
          name="password"
          type="password"
          value={values.password}
          error={errors.password ? true : false}
          onChange={onChange}
        />
                <p className="white-text">هل واجهتك مشكلة تواصل معنا عبر البريد الآلكتروني <a href='mailto:info@alsehaim.com'>هنا</a></p>

      <Button type="submit" primary size={'massive'}>تسجيل الدخول</Button>
      </Form>
      {Object.keys(errors).length > 0 && (
        <div className="ui error message">
          <ul className="list">
       <li>{errors.phone}</li> 
        <li>{errors.password}</li>
          </ul>
        </div>
      )}
    </div>
  );
}

const LOGIN_USER = gql`
  mutation login($phone: String!, $password: String!) {
    login(phone: $phone, password: $password) {
      id
      fname
      createdAt
      token
    }
  }
`;

export default Login;
