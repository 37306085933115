import React, { useContext,useState } from 'react';
import { AnimatedBox } from '../components/styled-components/elements/Boxes'
import { useSpring } from 'react-spring'
import { Grid,Button } from 'semantic-ui-react';
import {Link} from 'react-router-dom'
import { AuthContext } from '../context/auth';
import useWindowDimensions from '../util/hooks'

function Home() {
  const {  width } = useWindowDimensions();

  const { user,logout } = useContext(AuthContext);
  const [hover,setHover]=useState(false)
  const [hover2,setHover2]=useState(false)
  const {x,y} =useSpring({
     x : hover ?  1.2: 1,
     y:hover2 ?  1.2: 1,
  })
  return (
    <>
    {user?    <Grid columns={3}>
    <Grid.Row className="page-title">
      <h1>الموقع الرسمي لأسرة السحيم</h1>


<Link to='/users'>   <Button style={{marginTop:'10px'}} size='massive'>دليل الأسرة</Button> </Link>
      <br/>
      <Link to='/posts'> <Button style={{marginTop:'10px'}} size='massive'>ألبوم الأسرة</Button> </Link>
<br/>
     <Link to='/events'> <Button style={{marginTop:'10px'}} size='massive'>مناسبات الأسرة</Button> </Link>
     <Link to='/competition'> <Button style={{marginTop:'10px'}} size='massive'>مسابقة الهوية</Button> </Link>
     <h3 onClick={logout} className='footer'>تسجيل خروج</h3>
    </Grid.Row>
    <Grid.Row columns={4}>

    

   

    </Grid.Row>
  </Grid>:<>
  <Grid.Row >
  <h1 className="page-title">الموقع الرسمي لأسرة السحيم</h1>
       </Grid.Row>

       {width<601?
        (
          <>
          <Grid.Row >
          <Link to={'/Register'}>  <AnimatedBox first={true} onMouseOver={()=>setHover(true)} onMouseOut={()=> setHover(false)} 
       style={{transform:x.interpolate(x=>`scale(${x})`)}} >
      <h1>تسجيل حساب جديد </h1>
       </AnimatedBox></Link>
       </Grid.Row>
       <Grid.Row>
       <Link to={'/login'}>  <AnimatedBox first={false} onMouseOver={()=>setHover2(true)} onMouseOut={()=> setHover2(false)} 
       style={{transform:y.interpolate(y=>`scale(${y})`)}} >
       <h1>تسجيل الدخول</h1>
  
       </AnimatedBox></Link>
       </Grid.Row>
       </>
       )
       :
       ( 
         <>
         <Link to={'/Register'}>  <AnimatedBox first={true} onMouseOver={()=>setHover(true)} onMouseOut={()=> setHover(false)} 
       style={{transform:x.interpolate(x=>`scale(${x})`)}} >
      <h1>تسجيل حساب جديد </h1>
       </AnimatedBox></Link>
 

    <Link to={'/login'}>  <AnimatedBox first={false} onMouseOver={()=>setHover2(true)} onMouseOut={()=> setHover2(false)} 
       style={{transform:y.interpolate(y=>`scale(${y})`)}} >
       <h1>تسجيل الدخول</h1>
  
       </AnimatedBox></Link>
       </>
       )
      }
 
 


       <Grid.Row className="footer">
       <Link to='/number'>     <h3>طلب تغيير رقم الهاتف</h3></Link>
       </Grid.Row>
       </>}
</>
  );
}

export default Home;
