import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

import 'semantic-ui-css/semantic.min.css';
import './App.css';

import { AuthProvider } from './context/auth';
import AuthRoute from './util/AuthRoute';

import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Competition from './pages/Competition';
import Posts from './pages/Posts';
import Events from './pages/Events'
import { News } from './pages/News';
import { UsersList } from './pages/UsersList';
import GlobalStyle from './components/styled-components/Global'
import ChangePhoneForm from './pages/ChangePhoneForm';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Container>
        {/*  <MenuBar />*/}
          <Route exact path="/" component={Home} />
          <AuthRoute exact path="/login" component={Login} />
          <AuthRoute exact path="/register" component={Register} />
          
           <Route exact path="/posts" component={Posts} />
           <Route exact path="/users" component={UsersList} />
           <Route exact path="/events" component={Events} />
           <Route exact path="/competition" component={Competition} />

           <Route exact path="/posts/news" component={News} />
           <Route exact path="/number" component={ChangePhoneForm} />

         
        </Container>
      </Router>
      <GlobalStyle />
    </AuthProvider>
  );
}

export default App;
