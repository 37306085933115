import React from 'react';
import styled from 'styled-components';

const Wrap=styled.div`
    max-width: 1600px;
    margin: 0 auto;
    padding: 1em;
`
 
const Primary=styled.div`
    max-width: 650px;
    margin: 0 auto;
    font-family: 'Sen', sans-serif;
`

const Block = styled.div`
  cursor: pointer;
  background: transparent;
  font-size: 16px;
  border-radius: 3px;
  border: 2px solid darkgray;
  background-color:white;
  margin: 0 1em;
  padding: 0.25em 1em;
  margin-bottom: 3vh;
  margin-top: 1vh;
  color:#00909e;
  align-items:center;
`;
const H1 =styled.div`
color:#00909e;
font-size:2em;
margin-bottom:10px;
text-align: center;
`
const Body = () => (
    <Block>
      <H1>عنوان الخبر</H1>
      <p>محتوى الخبر</p>
      <p>تفاصيل عن الخبر  تفاصيل عن الخبر تفاصيل عن الخبر تفاصيل عن الخبر تفاصيل عن الخبر</p>      
    </Block>
)



export function News() {
  return (
      <Wrap>
      <Primary>        
        <Body/>
      </Primary>
      </Wrap>      
  );
}

