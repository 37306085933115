import React, { useContext, useState } from 'react';
import { Grid, Form } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {Button} from '../components/styled-components/elements/Buttons'
import { AuthContext } from '../context/auth';
import { useForm } from '../util/hooks';
import  '../App.css';
export default function ChangePhoneForm() {
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

  const { onChange, onSubmit, values } = useForm(loginUserCallback, {
    oldphone: '',
    newphone: '',
    name:''
  });
  function loginUserCallback() {
console.log(values)
  }
    return (
        <div className="form-container">
        <Form onSubmit={onSubmit} noValidate className={loading ? 'loading' : ''}>
        <Grid.Row >
        <h1 className="page-title">تغيير الرقم المسجل</h1>
        </Grid.Row>
        <label >رقم الجوال القديم</label>
          <Form.Input
           
            placeholder="٠٥٩٩٩٩٩٩٩٠"
            name="oldphone"
            type="text"
            value={values.oldphone}
            error={errors.oldphone ? true : false}
            onChange={onChange}
          />
          <label >رقم الجوال الجديد</label>
          <Form.Input
           
            placeholder="٠٥٩٩٩٩٩٩٩٠"
            name="newphone"
            type="text"
            value={values.newphone}
            error={errors.newphone ? true : false}
            onChange={onChange}
          />
          <label > مقدم طلب التغيير</label>
          <Form.Input
      
            placeholder="الاسم الثلاثي"
            name="password"
            type="password"
            value={values.name}
            error={errors.name ? true : false}
            onChange={onChange}
          />
         <Button type='submit'>رفع الطلب</Button>
        </Form>
        {Object.keys(errors).length > 0 && (
          <div className="ui error message">
            <ul className="list">
          {errors}
            </ul>
          </div>
        )}
      </div>
    )
}
