import styled from 'styled-components'
import { elevation } from '../utilites'

export const Button=styled.button`
    width:90%;
    padding: 5px 20px;
  border-radius: 4px;
  color: #098a96;
  font-size: 2rem;
  border: none;
  transition: 0.3s ease box-shadow;
  background: #fff;
  ${elevation[1]};
  &:hover {
    ${elevation[2]};
  }
  `;