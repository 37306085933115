import gql from 'graphql-tag';

export const FETCH_USERS_QUERY = gql`
  {
    users {
    fname
    sname
    tname
    foname
    phone
    city
    }
 
  }
`;
