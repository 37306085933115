import React, { useContext, useState } from 'react';
import { Grid, Form, Button } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { AuthContext } from '../context/auth';
import { useForm } from '../util/hooks';
import  '../App.css';

function Competition(props) {
  const context = useContext(AuthContext);
  const [errors, setErrors] = useState({});

  const { onChange, onSubmit, values } = useForm(createCompCallback, {
    link: '',
    notes: ''
  });

  const [createComp, { loading }] = useMutation(CREATE_COMP, {
    update(
      _,
      {
        data: { data: compData }
      }
    ) {
      props.history.push('/');
    },
    onError(err) {
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: values
  });

  function createCompCallback() {
    createComp();
  }

  return (
    <div className="form-container">
      <Form onSubmit={onSubmit} noValidate className={loading ? 'loading' : ''}>
      <Grid.Row className="page-title">
      <h1> مسابقة هوية السحيم </h1>
      </Grid.Row>
      <label > رابط المشاركة</label>
        <Form.Input
         
          placeholder="رابط المشاركة"
          name="link"
          type="text"
          value={values.link}
          error={errors.link ? true : false}
          onChange={onChange}
        />
        <label > ملاحظات</label>
        <Form.Input
    
          placeholder="ملاحظات"
          name="notes"
          type="textarea"
          value={values.text}
          error={errors.text ? true : false}
          onChange={onChange}
        />
        <p className="white-text">هل واجهتك مشكلة تواصل معنا عبر البريد الآلكتروني <a href='mailto:info@alsehaim.com'>هنا</a></p>

      <Button type="submit" primary size={'massive'}>ارسل مشاركتك</Button>
      </Form>
      {Object.keys(errors).length > 0 && (
        <div className="ui error message">
          <ul className="list">
        {errors}
          </ul>
        </div>
      )}
    </div>
  );
}

const CREATE_COMP = gql`
  mutation createCompetition($link: String!, $notes: String!) {
    createCompetition(link:$link,notes:$notes){
      id
    }
  }
`;

export default Competition;
