import React from 'react'
import { List,Grid,Breadcrumb } from 'semantic-ui-react'
import {Link} from 'react-router-dom'

const Events = props => (
  <>
  <Grid columns={3} >
   <Breadcrumb>
  <Breadcrumb.Section><Link to={'/'} >الصفحة الرئيسية</Link></Breadcrumb.Section>
  <Breadcrumb.Divider />
  <Breadcrumb.Section active> مناسبات الأسرة</Breadcrumb.Section>
</Breadcrumb>
  <Grid.Row className="page-title">
 
  <h1>مناسبات الأسرة</h1>
  </Grid.Row>
  </Grid>
  <h1>الحدث الحالي</h1>

  <div className="container">
  

  <div className="card-media">
    <div className="card-media-object-container">
        <a href='https://www.google.com/maps?q=%D9%82%D8%B5%D8%B1+%D8%A7%D9%84%D9%86%D8%AE%D8%A8%D8%A9+%D9%84%D9%84%D8%A7%D8%AD%D8%AA%D9%81%D8%A7%D9%84%D8%A7%D8%AA%D8%8C+Al+Lasib,+6909+%D8%A7%D9%84%D8%B7%D8%B1%D9%8A%D9%82+%D8%A7%D9%84%D8%AF%D8%A7%D8%A6%D8%B1%D9%8A+%D8%A7%D9%84%D8%BA%D8%B1%D8%A8%D9%8A%D8%8C+2500%D8%8C+Buraydah+52337%C2%A02500&ftid=0x1581fd80e518fe95:0xd0598e2b5cf53f60&hl=en-SA&gl=sa&entry=gps&shorturl=1'>      <div className="card-media-object" style={{ backgroundImage: `url(https://s3.eu-west-1.amazonaws.com/alsehaim.com/IMG_9458.jpg)` }} >  </div></a>
      <ul className="card-media-object-social-list">
        <li>
        </li>
      </ul>
    </div>
    <div className="card-media-body">
      <div className="card-media-body-top">
        <span className="subtle">الجمعة ١٢ شوال ١٤٤٣</span>
      </div>
      <span className="card-media-body-heading">اجتماع الأسرة السنوي</span>
      <div className="card-media-body-supporting-bottom">
        <span className="card-media-body-supporting-bottom-text subtle">قصر النخبة - الدائري الغربي - بريدة</span>
      </div>
      <div className="card-media-body-supporting-bottom card-media-body-supporting-bottom-reveal">
        <span className="card-media-body-supporting-bottom-text subtle">توجه الي مكان الحدث</span>
      </div>
    </div>
  </div>

</div>
 

</>
)
export default Events;