import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';

const GlobalStyle = createGlobalStyle`

  ${normalize()};
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
  @font-face {
    font-family: 'El Messiri';
    font-weight: normal;
    font-style: normal;
    src: url('https://fonts.googleapis.com/css?family=El+Messiri&display=swap');
  }

  body {
   
  }
  body {
    direction: rtl;
background: rgb(214,118,226);
background: linear-gradient(to right,  #00909e , #1b2a49 );
font-family: 'El Messiri', sans-serif;

    margin: 0;
    padding: 65px 0 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  main {
    width: 90%;
    margin: 0 auto;
  }
  h1{
      color:#fff;
  }
`;

export default GlobalStyle;
 