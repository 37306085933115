import styled from 'styled-components'
import { elevation,below } from '../utilites'
import {animated} from 'react-spring'


export const Box = styled.div`
width: 300px;
height: 300px;
background:#fff;
box-shadow: 5px 15px 10px -8px rgba(2,0,2,1);

border-radius:15px;


${below.med`
width: 200px;
height:200px;



`}

${below.large`


`

}
${below.small`
width: 100%;
height:100px;
top:${props =>props.first?'30%':'45%'};
left:0%;
marrgin-top:30px;




`

}
position: absolute;

left:${props =>props.first?'25%':'50%'};
h1 {
    ${below.med`
font-size:1.8rem;
`}
${below.small`
font-size:2rem;
margin-top:10px;
text-decoration:none;
`}
font-size:3rem;
  color:#098a96;
  margin-top:40%;
text-align: center;
text-decoration: none;
font-weight:bold;
}
${elevation[1]}

`

export const AnimatedBox = animated(Box)


