import React, { useContext, useState } from 'react';
import { Button, Form,Input,Select,Dropdown } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { AuthContext } from '../context/auth';
import { useForm } from '../util/hooks';
import  '../App.css';


function Register(props) {
  const context = useContext(AuthContext);
  const [errors, setErrors] = useState([]);

  const { onChange, onSubmit,onChangeSelect, values } = useForm(registerUser, {
    fname: '',
    sname: '',
    tname: '',
    foname: '',
    phone:'',
    showcontacts:false,
    city:'',
    maritalstatus:'',
    workstatus:'',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const [addUser, { loading }] = useMutation(REGISTER_USER, {
    update(
      _,
      {
        data: { register: userData }
      }
    ) {
      context.login(userData);
      props.history.push('/');
    },
    onError(err) {
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: values
  });

  function registerUser() {
    addUser();
  }
  const handleOnChange = (e, data) => {
    values[data.name] = data.value
 }
  return (
    <div className="form-container">
      <Form onSubmit={onSubmit} noValidate className={loading ? 'loading' : ''}>
        <h1>تسجيل حساب </h1>
        <Form.Group widths='equal'>
          <Form.Field
            control={Input}
            label='الأسم الأول'
            placeholder='الأسم الأول'
            name="fname"
            value={values.fname}
            onChange={onChange}
          />
          <Form.Field
            control={Input}
            label='اسم الاب'
            placeholder='أسم الاب'
            name="sname"
            value={values.sname}
            onChange={onChange}
          />
          <Form.Field
          control={Input}
          label='الاسم الجد'
          placeholder='الأسم الجد'
          name="tname"
          value={values.tname}
          onChange={onChange}
        />
        <Form.Field
        control={Input}
        label='اسم اب الجد'
        placeholder='اسم اب الجد'
        className='label'
        name="foname"
        value={values.foname}
        onChange={onChange}
      />
      </Form.Group>
      <Form.Group widths='equal'>
        <Form.Field
        control={Select}
        fluid
        options={[
  { key: 'اعزب', text: 'اعزب', value: 'اعزب' },
  { key: 'متزوج', text: 'متزوج', value: 'متزوج' },
        ]}
        label='الحالة الاجتماعية'
        className='label'
        name="maritalstatus"
        value={values.maritalstatus}
        onChange={onChangeSelect}
      />

        <Form.Field
        control={Select}
        options={[
  { key: 'طالب', text: 'طالب', value: 'طالب' },
  { key: 'موظف', text: 'موظف', value: 'موظف' },
  { key: 'غير ذلك', text: 'غير ذلك', value: 'غير ذلك' },
        ]}
        label='الحالة  الوظيفية'
        className='label'
        name="workstatus"
        value={values.workstatus}
        onChange={onChangeSelect}
      />
      </Form.Group>
        <Form.Input
          label="كلمة السر"
          placeholder="كلمة السر"
          name="password"
          type="password"
          value={values.password}
          error={errors.password ? true : false}
          onChange={onChange}
        />
        <Form.Input
          label="المدينة"
          placeholder="المدينة"
          name="city"
          
          value={values.city}
          onChange={onChange}
/>

<Form.Input
label="رقم الجوال"
placeholder="رقم الجوال"
name="phone"

value={values.phone}
onChange={onChange}
/>

        <br/>
        <p className="white-text">هل واجهتك مشكلة تواصل معنا عبر البريد الآلكتروني <a href='mailto:info@alsehaim.com'>هنا</a></p>

        <Button type="submit" primary size={'massive'}>
          التسجيل
        </Button>
      </Form>
      {Object.keys(errors).length > 0 && (
        <div className="ui error message">
          <ul className="list">
            {Object.values(errors).map((value) => (
              <li key={value}>{value}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

const REGISTER_USER = gql`
  mutation register(
    $fname:String! 
    $sname:String! 
    $tname:String! 
    $foname:String! 
    $phone:String!
    $workstatus:String!
    $maritalstatus:String!
    $showcontacts:Boolean!
    $city:String!
    $password:String!
  ) {
    register(
      registerInput: {
        fname:$fname 
        sname:$sname
        tname:$tname
        foname:$foname
        workstatus:$workstatus
        maritalstatus:$maritalstatus
        phone:$phone
        showcontacts:$showcontacts
        city:$city
        password: $password
 
      }
    ) {
      id
      fname
      createdAt
      token
    }
  }
`;


export default Register;
